module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TTWKHXZ","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"WeHotel Official Site","short_name":"Wehotel","start_url":"/","lang":"en","background_color":"#5F8089","theme_color":"#FF5252","display":"minimal-ui","icon":"src/images/weLogo.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-analytics/gatsby-browser.js'),
      options: {"plugins":[],"appId":"863289407505681","language":"en_US"},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"185428146079354"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
